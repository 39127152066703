
.top-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding-top: 15px;
}
.custom_font_42 {
  font-size: 2.4rem !important;
  line-height: 2.4rem !important;
}
.card_image {
  align-items: center;
  justify-items: center;
}

@media screen and (min-width: 956px) {
  .top-heading {
    margin: 1.8rem 0;
  }
  .top-heading p {
    width: 70% !important;
  }
  .fifthpage {
    width: 100%;
    height: 100vh;
    overflow: hidden;
   
  }
}

@media screen and (min-width: 770px) and (max-width: 900px) {
  .top-heading {
    margin: 6rem 0 !important;
  }
  .top-heading p {
    width: 70% !important;
  }
}

.top-heading p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  width: 60%;
  text-align: center;
}

@media screen and (min-width: 771px) and (max-width: 900px) {
  .top-heading {
    margin: 1rem 0;
    padding: 1rem;
  }
  .top-heading p {
    width: 100%;
  }
  .hustle-solo {
    padding: 3rem 0;
  }
}

@media screen and (max-width: 600px) {
  .top-heading {
    margin: 4rem 1rem;
    padding: 0rem;
  }
  .top-heading p {
    width: 100%;
  }
  .hustle-solo {
    margin: 0 ;
    padding: 0;
  }
  .custom_font_42 {
    font-size: 2rem !important;
    line-height: 2.4rem !important;
  }
  .fifthpage{
    width: 100%;
    height: 1470px !important;
    overflow: visible;
    
    margin-top: -90px!important;
  }
  .card_box{
    width: 80%!important;
   height: 300px !important;
    
  }
}

.top-heading span {
  color: var(--hero);
}

/* Hustle Sologan CSS Starts */

.hustle-solo {
  height: 250px;
  padding: 0 auto;
  margin: 0 auto;
}

.hustle-sologan {
  background: url("../assets/fifthpage/line.png") no-repeat center;
  /* height: 140px; */
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.letter {
  display: flex;
  margin: 4rem;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 2px solid #e2e2e2;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  cursor: pointer;
  transition: 0.6s all;
  position: relative;
  padding: 1.5rem;
}

@media screen and (min-width: 1300px) {
  .letter {
    padding: 2.5rem;
  }
}

.letter > h1 {
  margin: 0;
  color: #f96b04;
}

.letter:hover {
  background-color: #f96b04;
}

.letter:hover h1 {
  color: var(--white);
}

@media screen and (min-width: 770px) and (max-width: 991px) {
  .letter {
    margin: 1.6rem;
  }

  .letter-desc:nth-child(1) {
    transform: translateY(-40px) translateX(0px);
    width: 250px;
  }

  #h {
    width: 250px;
  }
}

@media screen and (min-width: 770px) {
  .letter:nth-child(1) {
    transform: translateY(-50px);
  }

  .letter:nth-child(2) {
    transform: translateY(-40px);
  }

  .letter:nth-child(3) {
    transform: translateY(60px);
  }

  .letter:nth-child(4) {
    transform: translateY(-20px);
  }

  .letter:nth-child(5) {
    transform: translateY(-40px);
  }

  .letter:nth-child(6) {
    transform: translateY(20px);
  }
}

@media screen and (min-width: 1300px) {
  .letter:nth-child(1) {
    transform: translateY(-20px);
  }

  .letter:nth-child(2) {
    transform: translateY(-40px);
  }

  .letter:nth-child(3) {
    transform: translateY(60px);
  }

  .letter:nth-child(4) {
    transform: translateY(-20px);
  }

  .letter:nth-child(5) {
    transform: translateY(-30px);
  }

  .letter:nth-child(6) {
    transform: translateY(20px);
  }
}

#e {
  width: 250px;
}

@media screen and (max-width: 769px) {
  .hustle-solo {
    padding: 4.5rem 0;
  }
  .top-heading p {
    width: 100%;
  }
  .hustle-desc {
    width: 100% !important;
  }

  .hustle-desc p {
    width: 100% !important;
  }

  .letter {
    margin: 0 auto;
  }

  #h,
  #u,
  #s,
  #t,
  #l,
  #e {
    width: 230px;
    font-size: 0.8rem;
  }

  #h {
    position: absolute;
    top: -130px;
    left: 100%;
  }

  #s {
    position: absolute;
    top: -130px;
    left: -100px;
  }

  #l {
    position: absolute;
    top: -130px;
    left: -280px;
  }

  #u {
    position: absolute;
    top: 90px;
    left: -25px;
  }

  #t {
    position: absolute;
    top: 90px;
    left: -190px;
  }

  #e {
    position: absolute;
    top: 90px;
    left: -360px;
  }
}

@media screen and (max-width: 442px) {
  #h,
  #u,
  #s,
  #t,
  #l,
  #e {
    width: 200px;
    font-size: 0.7rem;
  }

  .hustle-desc {
    width: 100% !important;
  }

  .hustle-desc p {
    width: 100% !important;
  }

  .letter {
    margin: 0 auto;
  }

  #h {
    position: absolute;
    top: -130px;
    left: 50%;
  }

  #s {
    position: absolute;
    top: -130px;
    left: -100px;
  }

  #l {
    position: absolute;
    top: -130px;
    left: -250px;
  }

  #u {
    position: absolute;
    top: 90px;
    left: -45px;
  }

  #t {
    position: absolute;
    top: 90px;
    left: -190px;
  }

  #e {
    position: absolute;
    top: 90px;
    left: -320px;
  }
}

@media screen and (max-width: 356px) {
  .hustle-desc {
    width: 100% !important;
  }

  .hustle-desc p {
    width: 100% !important;
  }

  .letter {
    margin: 0 auto;
  }

  #h,
  #u,
  #s,
  #t,
  #l,
  #e {
    width: 200px;
    font-size: 0.7rem;
  }

  #h {
    position: absolute;
    top: -130px;
    left: 100%;
  }

  #s {
    position: absolute;
    top: -130px;
    left: -40px;
  }

  #l {
    position: absolute;
    top: -130px;
    left: -150px;
  }

  #u {
    position: absolute;
    top: 90px;
    left: 0px;
  }

  #t {
    position: absolute;
    top: 90px;
    left: -100px;
  }

  #e {
    position: absolute;
    top: 90px;
    left: -210px;
  }
}

/* .letter:nth-child(1) {
    transform: translateY(-50px);
}

.letter:nth-child(3) {
    transform: translateY(60px);
}

.letter:nth-child(5) {
    transform: translateY(-40px);
}

.letter:nth-child(6) {
    transform: translateY(20px);
} */

.letter-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 120px;
  width: 350px;
  padding: 0 1rem;
  display: none;
  transition: 0.8s all;
}

.letter-desc h5 {
  color: var(--hero);
}

.letter-desc p {
  color: var(--black);
}

.letter-desc:nth-child(1) {
  position: absolute;
  top: -130px;
}

.letter:hover > .letter-desc {
  display: block;
}

/* Featured Card */

.featured-card {
  padding-bottom: 0px;
  height: 50px;
}

.f-card {
  display: flex;
  justify-content: center;
}

.card {
  padding: 1rem 2rem !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05) !important;
  align-items: center !important;
  justify-items: center !important;
  border: none !important;
  text-align: center !important;
  font-size: 13px !important;

  height: 280px;
  gap: 10px !important;
}

.img {
  background-color: #ececec;
  display: flex;
  border-radius: 50%;
  padding: 1rem;
}

.img img {
  width: 35px;
  height: 35px;
  justify-content: center !important;
  align-items: center !important;
}

.desc > p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 28px !important;
  display: flex;
  align-items: center;
  color: #9d9d9d;
}

.title p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--black);
  position: relative;
  top: 5px;
}
