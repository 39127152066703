@import url('https://fonts.googleapis.com/css2?family=Glory:wght@400;500;600;700&family=Lexend+Deca:wght@100;200;300;400;500&family=Outfit:wght@200;400;600&family=Poppins:wght@200;300;400;500&family=Roboto:wght@100;300;400;500&display=swap');


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --white: #fff;
  --black: #000;
  --hero: #F96B04;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.team,
.card-content{
  cursor: pointer;
}