.hero-page {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

}
.image_size {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -10;
}



.hero-content {
  padding: 10px 6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mid-content {
  margin-top: 2.8rem;
  display: flex;
  gap: 1rem;
  padding: 0;
  transform: translateY(20px);
  position: relative;
  height: 140px;
}

.words {
  display: block;
  overflow: hidden;
  /* height: 120px; */
}

.word-desc {
  display: block;
  overflow: hidden;
  height: 30px;
  padding: 0px 0;
  margin-top: 5px;
  margin-bottom: 5px;
  /* background-color: aquamarine; */
}

.word-desc p {
  display: block;
  color: var(--white);
  font-family: "Roboto";
  font-size: 50px;

  animation: spin-desc 12s infinite linear;
}

.words span {
  display: block;
  color: var(--hero);
  font-family: "Roboto";
  font-weight: 700;
  font-size: 108px;
  line-height: 141px;
  animation: spin 12s infinite linear;
  
}

@keyframes spin {
  0%, 26%
  {
    transform: translateY(0);
  }

  33%, 60%{
    transform: translateY(-100%);
  }

  66%, 93%{
    transform: translateY(-200%);
  }

  100% {
    transform: translateY(-300%);
  }
}

@keyframes spin-desc {
    0%, 26% {
    transform: translateY(0%);
  }

  33%, 60% {
    transform: translateY(-140%);
  }

  66%, 93% {
    transform: translateY(-280%);
  }

  100% {
    transform: translateY(-420%);
  }
}

.mid-content h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 108px;
  line-height: 141px;
  color: var(--white);
}

.last-content {
  margin-top: 0px;
  /* transform: translateY(-10px); */
}

.mid-content span {
  color: var(--hero);
}

.last-content p {
  font-family: "roboto";
  color: var(--white);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
}

.hero-btn {
  display: flex;
  gap: 10px;
}

.hero-btn button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 24px;
  background: var(--hero);
  border: none;
  background: #fa7e31;
}

.hero-btn button:nth-child(2) {
  background: transparent;
  border: 1px solid var(--hero);
}

@media screen and (max-width: 650px) {
  .hero-page {
    width: 100%;
    height: 50vh !important;
    overflow: visible !important;  
  }
  .image_size {
    width: 100%;
    height: 50vh !important;
    object-fit: cover !important;
  }
  
  
  
  .hero-content {
    padding: 10px 6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .mid-content {
    margin-top: 2.8rem;
    display: flex;
    gap: 1rem;
    padding: 0;
    transform: translateY(20px);
    position: relative;
    height: 140px;
  }
}

@media screen and (max-width: 424px) {
  .hero-content {
    padding: 100px 0 0 20px;
  }
  .image_size {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    z-index: -10;
  }
  .image_size {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    z-index: -10;
  }

  .mid-content {
    margin-top: 0 !important;
  }

  .mid-content h1,
  .words span {
    font-size: 65px !important;
  }

  .last-content {
    margin-top: 0px;
    transform: translateY(-20px);
  }

  .last-content p,
  .word-desc p {
    font-size: 16px !important;
  }

  .hero-btn {
    margin-top: 0;
    transform: translateY(-25px);
  }

  .hero-btn button {
    font-size: 16px;
    padding: 1px 5px;
  }
}

/* Hero Page Animation */

.words {
  overflow: hidden;
  /* height: 80px; */
}

@keyframes slide-up {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    transform: translateY(-90%);
    opacity: 0;
  }
}


