.about-us {
    display: flex;
    position: relative;
    padding: 0 0;
    max-height: 100vh;
   
}

.about-left {
    width: 50%;
    padding: 4rem 6rem;
    color: #fff;
    background: linear-gradient(180deg, #131618 0%, rgba(19, 22, 24, 0.85) 100%);
}
.about-left span {
    color: var(--hero)
}

.about-left>h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 75px;
    padding-bottom: 10px;
}

.about-left>h3::before {
    content: '';
    position: absolute;
    background-color: var(--hero);
    width: 120px;
    height: 3px;
    margin: 4rem 0;
}

.about-desc p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 34px;
    
    
}

.about-right {
    width: 50%;
    margin-top: 0px;
}

.about-right img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

@media screen and (max-width:900px) {
   
    
    .about-us {
       display: inline;
       
    }

    .about-left {
        width: 100%;
       
    }

    .about-right {
        width: 100%;
        
    }

    .about-right img {
        object-fit: cover;
    height: fit-content;
    }
}



@media screen and (max-width:676px) {
    .about-left {
        width: 100%;
        padding: 1rem 1.4rem;
    
    }
    .about-desc p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        
        
    }
    .about-right {
        width: 100%;
        height: auto !important;
       
    }
    .about-right img {
        width: 100%;
        padding: 0 0;
        
    }
}