.second-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* border: red 2px solid !important; */
}

.sec-page {
  height: 60vh;
  overflow: hidden;
  top: 0;
  bottom: 0;
}
.third-page {
  width: 100%;

  overflow: hidden;
  height: 30vh;
}
.fourth-page {
  width: 100%;
  padding-top: 0rem;
  padding-bottom: 0rem;
  overflow: hidden;
  height: 10vh;
  top: 0;
  bottom: 0;
}

.sec-card-f {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #f3f3f3;
}

.sec-card-f,
.sec-card {
  height: 60vh;
  padding: 7.2rem 2rem;
}

@media screen and (max-width: 768px) {
  .sec-card-f {
    padding: 0 0.5rem;
    height: 100px;
    margin-top: 0px;
  }

  .sec-card-f p {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    transform: translateY(110px);
  }

  .sec-card {
    height: 42vh;
    padding: 2rem 2rem !important;
    overflow: visible;
  }

  .sec-card-f p {
    font-size: 67px !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 600px) {
  .hero-wrapper {
    width: 100%;
    height: 175px;
    display: flex;
  }
  .slider .slide {
    width: 250px;
    background-color: var(--hero);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px !important;
  }
  .slider {
    height: 60px !important;
  }
  .fourth-page {
    width: 100%;

    overflow: visible !important;
    height: 170px !important;
    margin-top: 100px !important;
  }
  .second-page {
    width: 100%;
    height: 1600px !important;
    overflow: visible;
  }
  .sec-card-f p {
    font-size: 45px !important;
    width: 100% !important;
    height: 100px !important;
    margin-top: -20px !important;
  }

  .sec-page {
    height: 750px;
    overflow: visible;
    top: 0;
    bottom: 0;
  }
  .third-page {
    width: 100%;
    overflow: visible;
    height: 520px !important;
  }

  .sec-card-f {
    padding: 0 0.5rem;
    height: 80px !important;
    padding-top: -18px !important;
  }
  #discover {
    margin-top: -30px !important;
  }
  .third-card {
    width: 95% !important;
    display: flex;
    margin: 0 20px 0 10px !important;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
    height: 300px !important;
    overflow: visible;
    /* border: red 5px solid !important; */

    /* min-height: 200px !important; */
  }

  #third-text {
    /* font-size: 22px; */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    margin-top: 10px !important;
    border: red 5px solid !important;
    height: 200px !important;
    overflow: visible;
  }
}

.sec-card-f p {
  font-family: "roboto";
  width: 150px;
  height: 30vh !important;
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 68px;
  color: #363434;
}

.sec-card-f span {
  color: var(--hero);
}

.sec-cards {
  padding: 0;
  margin: 0;
  position: relative;
}

.sec-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.head {
  display: flex;
}

.head h3::after {
  content: "";
  position: absolute;
  background-color: var(--hero);
  height: 2px;
  display: flex;
  width: 105px;
}

.head h3 {
  color: var(--hero);
  font-family: "roboto";
  font-size: 40px;
}

.desc > p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

#desc-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 34px;
  /* width: 360px; */
}

.my-text {
  color: rgb(0, 0, 0) !important;
  font-weight: 400 !important;
  /* width: 300px; */
}

.sec-card:nth-child(2) {
  background: linear-gradient(180deg, #131618 0%, rgba(19, 22, 24, 0.85) 100%);
}

/* second page end */

/* third page start */

.third-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5.5rem 3rem;
  height: 30vh;
  /* min-height: 200px !important; */
}

.third-card:nth-child(1) {
  background: linear-gradient(180deg, #131618 0%, rgba(19, 22, 24, 0.85) 100%);
}

.third-card:nth-child(2) {
  padding: 1rem 3rem !important;
}

#third-text {
  /* font-size: 22px; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  margin-top: 10px !important;
  border: red 2px !important;
}

.third-card:nth-child(1) p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  margin: 0 auto;
}

.third-card:nth-child(2) p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #171a1c;
}

.third-card:nth-child(1) span {
  color: var(--hero);
}

/* fourth banner start  */

.hero-wrapper {
  width: 100%;
  /* height: 75px; */
  display: flex;
}

@media screen and (max-width: 956px) {
  .slider {
    width: 100% !important;
  }
  .hero-wrapper {
    display: block;
  }
  .wrapper-sec {
    height: 70px !important;
  }
}

.wrapper-sec {
  width: inherit !important;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #131618 0%, rgba(19, 22, 24, 0.85) 100%);
}

.wrapper-sec p {
  margin: 0 auto;
  color: var(--white);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
}

.slider {
  background: rgb(220, 101, 101);
  background: var(--hero);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 88px;
  overflow: hidden;
  position: relative;
  width: 160%;
  align-items: center;
}

.slider .slide-track {
  animation: scroll 15s linear infinite;
  display: flex;
  width: calc(250px * 14);
  align-items: center;
}

.slider .slide {
  height: 90px;
  width: 250px;
  background-color: var(--hero);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.slide p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold !important;
  letter-spacing: 1px;
  font-size: 30px;
  line-height: 40px;
  margin-top: 10px;
  color: #140e07;
  margin: 0 auto;
  align-items: center;
}

.slide p::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #140e07;
  border-radius: 50%;
  margin: 12px 34px;
  text-align: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}
