.header {
    padding: 10px 6rem;
    opacity: 0.9;
    background: rgba(0, 0, 0, 0.36);
    backdrop-filter: blur(5px);
    position: absolute;
    width: 100%;
    height: 66px;
    z-index: 999;
}
.nav-link{
    cursor: pointer;
    font-family: 'roboto'!important;
    font-weight: 600;
    
}
.nav-link:hover{
    color: var(--hero);
}

@media screen and (max-width:650px) {
    .header{
        padding: 0 10px;
    }
}

.nav-item {
    margin: 0 10px;
}

.nav-item a {
    font-family: 'lexend deca';
    font-weight: 400;
    font-size: 17px;
}

.hamburger {
    display: none;
}

@media screen and (max-width:991px) {
    .mobile-device {
        position: absolute !important;
        display: block !important;
        top: 0 !important;
        left: 0 !important;
        background-color: var(--black);
        width: 100% !important;
        height: 100vh !important;
        z-index: 9 !important;
    }

    .mobile-device ul {
        margin: 4rem 0;
        display: flex;
        gap: 2rem;
    }

    .mobile-device ul li {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-device ul li a{
        font-size: 1.2rem;
    }

    .hamburger {
        display: block;
        position: relative;
        z-index: 999;
    }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .navbar-brand img{
        width: 80%;
        height: 100%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}