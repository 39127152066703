.Eighth-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  
}
.team-container {
  display: flex;
}
.team-height {
  height: 46vh;
  overflow: hidden;
  /* border: blueviolet 1px solid; */
}
.event-height {
  height: 28vh;
  overflow: hidden;
  /* border: yellow 1px solid; */
}
.footer-height {
  height: 30vh;
  overflow: hidden;
  /* border: red 1px solid; */
}

@media screen and (max-width: 767px) {
  .team-container {
    display: block;
  }

  .team-heading {
    max-width: 100% !important;
  }

  .team-heading p {
    margin: 0 auto;
    padding: 1rem 0;
  }

  .team-details {
    display: flex;
    justify-content: center;
  }

  .teams {
    margin: 1rem 0 !important;
  }
}

.team-heading {
  background: #f9f9f9;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  padding: 0 3rem;
}

.team-heading p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 72px;
  color: #0a0a0a;
}

.team-heading span {
  color: var(--hero);
}

.team-details {
  /* background-color: aquamarine; */
  padding: 0;
  margin: 0;
}

.team-card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  padding: 0;
}

.teams,
.team {
  /* padding: 2rem; */
  padding: 0 0;
  justify-content: center;
}

.teams {
  gap: 5px;
  margin: 0;
}

.team {
  width: 180px;
  /* border: red 1px solid; */
  padding: 1rem;

  background-color: var(--white);
  background: #ffffff;
  box-shadow: 0px 0px 90px rgba(0, 0, 0, 0.04);
}

.team-img img {
  width: 70px;
}

@media screen and (min-width: 1500px) {
  .team {
    width: 350px;
  }
}

@media screen and (min-width: 1500px) {
  .team {
    height: 160px;
  }
}
@media screen and (min-width: 1024px) {
  .team {
    width: 350px;
  }
}

@media screen and (min-width: 1024px) {
  .team {
    height: 155px;
  }
}

.team-role p {
  font-size: 15px;
}

.team-name p {
  position: relative;
  top: 5px;
  font-weight: 600;
  font-size: 17px;
  /* margin: 0 auto; */
}

/* team page end  */
/* event page start  */

.team-container {
  position: relative;
}

.t-head {
  /* padding: 4rem; */
  background: linear-gradient(180deg, #131618 0%, rgba(19, 22, 24, 0.85) 100%);
}

.t-head p {
  color: var(--white);
}

@media screen and (max-width: 768px) {
  .t-head {
    padding: 0px;
  }
}

.event-carousel {
  display: flex;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 600px) {
  .teams {
    gap: 2px;
    margin: 0;
  }

  .team {
    width: 100%;
  }

  .teams {
    height: 700px !important;
    /* border: blue 5px solid !important; */
    /* z-index: 10; */
  }
  .team-heading {
    font-size: 1rem !important;
    height: 200px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    /* border: greenyellow 2px solid !important; */
    margin-top: -1rem !important;
  }

  .Eighth-page {
    width: 100%;
    height: 1700px !important;
    overflow: visible;
    /* border: red 5px solid !important; */
  }
  .team-container {
    display: block;
  }
  .team-height {
    height: 1400px !important;
    overflow: visible;
    /* border: blueviolet 1px solid; */
  }
  .event-height {
    height: 380px !important;
    overflow: visible;
    /* border: yellow 5px solid; */
  }
  .footer-height {
    height: 460px !important;
    overflow: visible;
    /* border: red 5px solid; */
  }
}
.event {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  animation: scroll-brand 15s linear infinite;
  /* animation: event-slide 5s ease-in infinite alternate-reverse; */
}

@keyframes scroll-brand {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-375%);
  }
}

.event > img {
  /* width: 100%; */
  width: 300px;
  height: 100%;
  object-fit: fill;
}

.event-title {
  display: flex;
  position: absolute;

  background-color: rgba(63, 64, 64, 0.692);
  width: 100%;
  height: 100%;
  opacity: 0.9;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  top: 0;
}

.event-title svg {
  margin-top: -10px;
}

.event-title p,
svg {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 25px;
  line-height: 54px;
  color: var(--white);
}
/* event page end  */
/* footer page strt  */
.hero-footer {
  width: 100%;
  height: fit-content;
  background-color: var(--black);
  bottom: 0;
}

.footer-content {
  padding: 1.5rem;
}

.left-footer {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

.right-footer {
  display: block;
}

.footer-logo {
  display: flex;
  justify-content: space-between;
}

.footer-logo img {
  width: 130px;
}

.connect {
  margin-right: 20%;
}
.connect p {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--white);
}

.social-icons {
  display: flex;
  top: 8px;
  justify-content: center;
}
.social-icons a {
  display: flex;
  flex-direction: row;
  margin-right: 20px !important;
}

.social-icons svg {
  width: 100%;
  color: var(--white);
  font-size: 22px;
}

.right-footer {
  position: relative;
}

.right-footer-details {
  display: flex;
  justify-content: center;
  margin: auto 0;
  gap: 2rem;
}

.company ul {
  margin: 0;
  padding: 0;
}

.contact {
  width: 220px;
}

.contact ul {
  margin: 0;
  padding: 0;
}

.company li {
  list-style: none;
  margin: 5px 0;
}

.contact li {
  list-style: none;
  margin: 5px 0;
}

.company,
.contact li {
  color: var(--white);
  font-family: "lexend deca";
  font-weight: 350;
}

.company,
.contact p {
  color: var(--white);
  font-family: "lexend deca";
  font-weight: bold;
}
.footer-map iframe {
  border-radius: 10px;
}

#cmp,
#con {
  font-weight: bold;
}

.copyright {
  width: 100%;
  display: flex;
  justify-content: center;
}

.copyright p {
  color: #818181;
  font-size: 14px;
  margin: 0px 20px;
  margin-top: -20px !important;
}

@media screen and (min-width: 100px) {
  .copyright p {
    position: relative;
    left: -20px;
  }
}

@media screen and (max-width: 768px) {
  .copyright {
    position: relative;
    top: 0px;
  }
  .footer-map {
    visibility: hidden;
  }
  .connect {
    margin-left: 50%;
  }
}

.social-icons-logo {
  width: 25px !important;
  height: 25px !important;
}

#address {
  position: relative;
  top: 8px;

  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .social-icons span {
    width: 100px !important;
    height: 100px !important;
  }

  .copyright p {
    font-size: 14px;
    margin-top: 10px !important;
  }
  .footer-logo img {
    width: 100%;
    height: 70px;
  }
  .social-icons-logo {
    width: 20px !important;
    height: 20px !important;
  }
}
/* footer page end  */
